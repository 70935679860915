import {useAPI} from "@/composables/useAPI";
//import {SWHelpers} from "@/helpers";

const {post, isLoading} = useAPI()
// eslint-disable-next-line no-unused-vars
//const {unpackJSONAPI} = SWHelpers()
//const whitelist = ["categories", "mainCategories", "manufacturer"]

export default {
    async getOptions(id, page) {
        const body = {
            "page": page,
            "associations":
                {
                    "group":
                        {
                            "total-count-mode": 1
                        }
                },
            "total-count-mode": 1
        }
        body.sort = [{
            "field": "property_group_option.name",
            "naturalSorting": false,
            "order": "ASC"
        }];

        const params = new URLSearchParams({})

        let result = await post('/search/property-group/'+id+'/options', params, body)

        //result = unpackJSONAPI(result, whitelist);

        //console.log(result)
        return result

    },

    loading() {
        return isLoading.value
    }
}
