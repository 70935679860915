<script setup>

import BasicNavigationItem from "@/components/Navigation/BasicNavigationItem.vue";
import {useAPI} from "@/composables/useAPI";


const { logout, currentUser } = useAPI()


</script>

<template>
    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
        <li class="border-top-1 surface-border lg:border-top-none lg:relative">
            <a
                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple border-none"
            >
                <Avatar icon="pi pi-user" size="large" shape="circle" />

                <div class="block ml-2 ms-2">
                    <div class="text-900 font-medium lh-1">
                        {{ currentUser.username }}
                    </div>
                </div>
            </a>
            <ul class="list-none py-1 px-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                <BasicNavigationItem
                    v-styleclass="{ selector: '@parent', leaveToClass: 'hidden', leaveActiveClass: 'fadeout' }"
                    label="Account"
                    :route="{ name: 'account' }"
                    icon="pi-user"
                />
                <a
                    v-styleclass="{ selector: '@parent', leaveToClass: 'hidden', leaveActiveClass: 'fadeout' }"
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium cursor-pointer hover:surface-100"
                    @click="logout()"
                >
                    <i class="pi pi-sign-out mr-2" />
                    <div class="block ml-2">
                        <div class="text-900 font-medium lh-1">Logout</div>
                    </div>

                </a>
            </ul>
        </li>
    </ul>
</template>

<style scoped>

</style>