import { createRouter, createWebHistory } from 'vue-router'
import {useAPI} from "@/composables/useAPI";

// 1. Define route components.
import LoginComponent from "@/components/Account/LoginComponent.vue";

// 2. Define some routes
// Each route should map to a component.
const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginComponent
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/components/Home/HomeComponent.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/components/Account/EditAccount.vue')
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/components/Order/OrderListComponent.vue')
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/components/Statistics/StatsComponent.vue'),
        children: [
            {
                path: 'order',
                name: 'order-statistics',
                component: () => import('@/components/Statistics/OrderStatistics.vue'),
            },
            {
                path: 'billing-reports',
                name: 'billing-reports',
                component: () => import('@/components/Statistics/BillingsReportOverview.vue'),
            },
            {
                path: 'looker-studio',
                name: 'looker-studio',
                component: () => import('@/components/Statistics/LookerStudioReport.vue'),
            },
        ]
    },
    {
        path: '/product',
        name: 'product-overview',
        component: () => import('@/components/Product/ProductOverview.vue'),
        children: [
            {
                path: 'list',
                name: 'product-list',
                component: () => import('@/components/Product/List/ProductListDTComponent.vue'),
            },
            {
                path: 'detail/:productId',
                name: 'product-detail',
                component: () => import('@/components/Product/Detail/ProductDetailComponent.vue')
            },
        ]
    },
    {
        path: '/store',
        name: 'store',
        component: () => import('@/components/StoreInformation/StoreComponent.vue'),
        children: [
            {
                path: 'store-settings',
                name: 'store-settings',
                component: () => import('@/components/StoreInformation/StoreDataComponent.vue'),
            },
            {
                path: 'legal-documents',
                name: 'legal-documents',
                component: () => import('@/components/StoreInformation/LegalDocumentsComponent.vue'),
            },
        ]
    }
];

// 3. Create the router instance and pass the `routes` option
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes, // short for `routes: routes`
})

router.beforeEach((to, from) => {
    const { currentUser } = useAPI()

    const isAuthenticated = Object.keys(currentUser.value).length > 0;

    if (!isAuthenticated && to.name !== 'login') {
        // redirect the user to the login page
        return { name: 'login' };
    }

    if(isAuthenticated && to.name === 'login') {
        return { name: 'home' };
    }
});

