<script setup>

const props = defineProps({
    route: { type: Object, required: true},
    label: { type: String, required: true},
    icon: { type: String, required: false, default: '' },
    condition: { type: Boolean, default: true }
})

</script>

<template>
    <li v-if="props.condition">
        <router-link
            :to="props.route"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
        >
            <i
                class="pi mr-2"
                :class="props.icon"
            />
            <span>{{ props.label }}</span>
        </router-link>
    </li>
</template>

<style scoped>
    a {
        text-decoration: none;
    }
</style>