import {useAPI} from "@/composables/useAPI";
import {SWHelpers} from "@/helpers";

const {post, isLoading} = useAPI()
// eslint-disable-next-line no-unused-vars
const {unpackJSONAPI} = SWHelpers()
const whitelist = ["categories", "mainCategories", "manufacturer"]

export default {
    async getCategories(filter, page, limit) {
        const body = {
            "page": page,
            "limit": limit,
            "filter":
                [

                ],
            "total-count-mode": 1
        }

        body.filter = []
        body.filter.push({
            "type": "equals",
            "field": "parentId",
            "value": filter.parentId
        })

        const params = new URLSearchParams({})
        let result = await post('/search/category', params, body)

        result = unpackJSONAPI(result, whitelist);

        //console.log(result)
        return result

    },

    // loads Shopware Categories in PrimVue Node Format
    async getCategoriesNode(filter, page, limit) {
        const body = {
            "page": page,
            "limit": limit,
            "filter":
                [
                ],
            "total-count-mode": 1
        }
        body.filter.push({
            "type": "equals",
            "field": "parentId",
            "value": filter.parentId
        })
        const params = new URLSearchParams({})
        let result = await post('/search/category', params, body)
        result = unpackJSONAPI(result, whitelist)

        const nodes = []
        result.data.forEach(function(el) {
            const tmp ={key: el.id, label: el.attributes.name}
            tmp.leaf = el.attributes.childCount <= 0;
            if (!tmp.leaf) tmp.children = []
            nodes.push(tmp)
        })

        return nodes
    },

    async getCategoryById(categoryId) {
        const body = {
            "page": 1,
            "limit": 1,
            "ids": categoryId,
        }

        const params = new URLSearchParams({})
        let result = await post('/search/category', params, body)
        result = unpackJSONAPI(result, whitelist);

        return result
    },

    loading() {
        return isLoading.value
    }
}
