<script setup>

import BasicNavigationItem from "@/components/Navigation/BasicNavigationItem.vue";

const props = defineProps({
    label: { type: String, required: true },
    icon: { type: String, required: false, default: '' },
    children: { type: Array, required: true },
    condition: { type: Boolean, default: true }
});

</script>

<template>
    <li
        v-if="props.condition"
        class="lg:relative"
    >
        <a
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple border-none"
        >
            <i
                class="pi mr-2"
                :class="props.icon"
            />
            <span>{{ props.label }}</span>
            <i class="pi pi-angle-down ml-auto lg:ml-3" />
        </a>
        <ul class="list-none py-1 px-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
            <BasicNavigationItem
                v-for="child in props.children"
                :key="child.label"
                v-styleclass="{ selector: '@parent', leaveToClass: 'hidden', leaveActiveClass: 'fadeout' }"
                :label="child.label"
                :route="child.route"
                :icon="child.icon"
            />
        </ul>
    </li>
</template>

<style scoped>

</style>