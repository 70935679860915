import {useAPI} from "@/composables/useAPI";

const { post, get, del, postFile } = useAPI()

export default {
    normalizeExtensionName(entity_name) {
        return entity_name.replaceAll('_', '-');
    },

    async getById(entity_name, id) {
        const api_entity_name = this.normalizeExtensionName(entity_name);
        const params = new URLSearchParams();
        return await get(`/${api_entity_name}/${id}`, params)
    },

    async searchByParameters(entity_name, filters) {
        const api_entity_name = this.normalizeExtensionName(entity_name);
        const body = {
            'limit': 1,
            'filter': filters
        }

        const params = new URLSearchParams();
        return await post(`/search/${api_entity_name}`, params, body);
    },

    async create(entity_name, payload) {
        const api_entity_name = this.normalizeExtensionName(entity_name);
        const params = new URLSearchParams();
        return await post(`/${api_entity_name}`, params, payload, true, 'default', true);
    },

    async upsert(entity_name, payload) {
        const body = {
            'write-entity': {
                entity: entity_name,
                action: 'upsert',
                payload: [payload]
            }
        }

        const params = new URLSearchParams();
        return await post('/_action/sync', params, body, )
    },

    async delete(entity_name, id) {
        const api_entity_name = this.normalizeExtensionName(entity_name);
        const params = new URLSearchParams();
        return await del(`/${api_entity_name}/${id}`, params, true, true);
    },

    async uploadMedia(id, file, format, fileName = null) {
        if(! fileName) {
            fileName = id + '.' + format;
        }
        const params = new URLSearchParams({ extension: format, fileName: fileName });
        return await postFile(`/_action/media/${id}/upload`, params, file);
    }


}