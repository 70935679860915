import {useAPI} from "@/composables/useAPI";
import {SWHelpers} from "@/helpers";
import settings from "@/settings";
import crypto from "crypto";
import {useRoles} from "@/composables";

const {post, postFile, patch, callUrl, currentUser, isLoading} = useAPI()
// eslint-disable-next-line no-unused-vars
const {unpackJSONAPI} = SWHelpers()
const { shopConfig } = useRoles();

const whitelist = ["categories", "mainCategories", "manufacturer"]

export default {
    async getProducts(filter, page, limit) {
        // Hersteller ID finden nach der wir filtern müssen, ist in den Settings über den GruppenNamen gemappt
        let manufacturerId = shopConfig.value.manufacturer.join('|')


        if (filter.manufacturer.length > 0) {
            manufacturerId = filter.manufacturer
        }
        const body = {
            "page": page,
            "limit": limit,
            "filter":
                [
                    {
                        "type": "equals",
                        "field": "product.parentId",
                        "value": null
                    },
                    {
                        "type": "equalsAny",
                        "field": "manufacturer.id",
                        "value": manufacturerId
                    }
                ],
            "sort":
                [
                    {
                        "field": "productNumber",
                        "order": "DESC",
                        "naturalSorting": false
                    }
                ],
            "associations":
                {
                    "cover":
                        {
                            "total-count-mode": 1
                        },
                    "manufacturer":
                        {
                            "total-count-mode": 1
                        },
                    "media":
                        {
                            "total-count-mode": 1
                        },
                    "visibilities": {
                        "total-count-mode": 1
                    },
                    "categories":
                        {
                            "total-count-mode": 1
                        }
                },
            "total-count-mode": 1}

        // TODO -> Sorting etc.
        body.query = [];
        if (filter.searchtext !== '') {

            body.query.push({
                "query": {
                    "field": "product.name",
                    "type": "equals",
                    "value": filter.searchtext
                },
                "score": 500
            },
            {
                "query": {
                    "field": "product.name",
                    "type": "contains",
                    "value": filter.searchtext
                },
                "score": 375
            },
            {
                "query": {
                    "field": "product.productNumber",
                    "type": "equals",
                    "value": filter.searchtext
                },
                "score": 500
            },
            {
                "query": {
                    "field": "product.productNumber",
                    "type": "contains",
                    "value": filter.searchtext
                },
                "score": 375
            })
        }

        if (filter.statusFilter === 'active') {
            body.filter.push({
                "field": "product.active",
                "type": "equals",
                "value": true
            })
        }

        if (filter.statusFilter === 'inactive') {

            body.filter.push({
                "type": "multi",
                "operator": "and",
                "queries": [
                    {
                        "type": "equals",
                        "field": "product.active",
                        "value": false
                    },
                    {
                        "type": "equals",
                        "field": "product.customFields.custom_vendorportal_pending_approval",
                        "value": false
                    }
                ]
            })
        }

        if (filter.statusFilter === 'inactiveInReview') {
            body.filter.push(
                {
                    "field": "product.customFields.custom_vendorportal_pending_approval",
                    "type": "equals",
                    "value": true
                })
        }

        /*
        if (filter.manufacturer !== '') {
            body.query.push(
                {
                    "query": {
                        "field": "product.manufacturerId",
                        "type": "equals",
                        "value": filter.manufacturer
                    }
                }
            )
        }*/

        // Sorter derzeit updated AT hartcodiert
        body.sort = [{
            "field": "product.updatedAt",
            "naturalSorting": false,
            "order": "ASC"
        }];

        //console.log(body)
        const params = new URLSearchParams({})
        let result = await post('/search/product', params, body)

        result = unpackJSONAPI(result, whitelist);

        //console.log(result)
        return result

    },

    async searchProductsSimple(filter, sort, page, limit) {
        // Hersteller ID finden nach der wir filtern müssen, ist in den Settings über den GruppenNamen gemappt
        let manufacturerId = shopConfig.value.manufacturer.join('|')


        if (filter.manufacturer.value && filter.manufacturer.value !== '') {
            manufacturerId = filter.manufacturer.value
        }

        const body = {"page": page,
            "limit": limit,
            "filter":
                [
                    {
                        "type": "equals",
                        "field": "product.parentId",
                        "value": null
                    },
                    {
                        "type": "equalsAny",
                        "field": "manufacturer.id",
                        "value": manufacturerId
                    }
                ],
            "sort":
                [
                    {
                        "field": "productNumber",
                        "order": "DESC",
                        "naturalSorting": false
                    }
                ],
            "associations":
                {
                    "cover":
                        {
                            "total-count-mode": 1
                        },
                    "manufacturer":
                        {
                            "total-count-mode": 1
                        },
                    "media":
                        {
                            "total-count-mode": 1
                        },
                    "visibilities": {
                        "total-count-mode": 1
                    },
                    "categories":
                        {
                            "total-count-mode": 1
                        }
                },
            "total-count-mode": 1}

        // TODO -> Sorting etc.
        body.query = [];

        if (filter.searchtext && filter.searchtext !== '') {

            body.query.push(
                {
                    "query": {
                        "field": "product.name",
                        "type": "equals",
                        "value": filter.searchtext
                    },
                    "score": 500
                },
                {
                    "query": {
                        "field": "product.name",
                        "type": "contains",
                        "value": filter.searchtext
                    },
                    "score": 375
                },
                {
                    "query": {
                        "field": "product.productNumber",
                        "type": "equals",
                        "value": filter.searchtext
                    },
                    "score": 500
                },
                {
                    "query": {
                        "field": "product.productNumber",
                        "type": "contains",
                        "value": filter.searchtext
                    },
                    "score": 375
                })
        }

        // Set Filters for Value
        for (const [fkey, frow] of Object.entries(filter)) {
            if (frow.value && frow.value !== '' && fkey !== 'active' && fkey !== 'approval'  && fkey !== 'manufacturer') {
                body.filter.push({
                    "field": fkey,
                    "type": "contains",
                    "value": frow.value
                })
            }
        }

        if (filter.active) {
            if (filter.active.value === 'active') {
                body.filter.push({
                    "field": "product.active",
                    "type": "equals",
                    "value": true
                })
            }
            if (filter.active.value === 'inactive') {
                body.filter.push({
                    "field": "product.active",
                    "type": "equals",
                    "value": false
                })
            }
        }

        if (filter.approval) {
            if (filter.approval.value === 'approved') {
                body.filter.push({
                    "field": "product.customFields.custom_vendorportal_pending_approval",
                    "type": "equals",
                    "value": false
                })
            }
            if (filter.approval.value === 'pending') {
                body.filter.push({
                    "field": "product.customFields.custom_vendorportal_pending_approval",
                    "type": "equals",
                    "value": true
                })
            }
        }

        if (sort.field) {
            body.sort = [
                {
                    "field": sort.field,
                    "order": sort.order,
                    "naturalSorting": false
                }
            ]
        } else {
            body.sort = [
                {
                    "field": "createdAt",
                    "order": "DESC",
                    "naturalSorting": false
                }
            ]
        }

        const params = new URLSearchParams({})
        let result = await post('/search/product', params, body, false, 'simple')

        //console.log(result)
        return result
    },

    async getProductDetail(id) {
        const body = {
            "associations": {
                "categories": {
                    "total-count-mode": 1
                },
                "cover": {
                    "total-count-mode": 1
                },
                "customFieldSets": {
                    "total-count-mode": 1
                },
                "mainCategories": {
                    "total-count-mode": 1
                },
                "manufacturer": {
                    "associations": {
                        "media": {
                            "total-count-mode": 1
                        }
                    },
                    "total-count-mode": 1
                },
                "media": {
                    "sort": [
                        {
                            "field": "position",
                            "naturalSorting": false,
                            "order": "ASC"
                        }
                    ],
                    "total-count-mode": 1
                },
                "options": {
                    "associations": {
                        "group": {
                            "total-count-mode": 1
                        }
                    },
                    "total-count-mode": 1
                },
                "prices": {
                    "sort": [
                        {
                            "field": "quantityStart",
                            "naturalSorting": true,
                            "order": "ASC"
                        }
                    ],
                    "total-count-mode": 1
                },
                "properties": {
                    "sort": [
                        {
                            "field": "name",
                            "naturalSorting": true,
                            "order": "ASC"
                        }
                    ],
                    "total-count-mode": 1
                },
                "seoUrls": {
                    "filter": [
                        {
                            "field": "isCanonical",
                            "type": "equals",
                            "value": true
                        }
                    ],
                    "total-count-mode": 1
                },
                "tags": {
                    "sort": [
                        {
                            "field": "name",
                            "naturalSorting": false,
                            "order": "ASC"
                        }
                    ],
                    "total-count-mode": 1
                },
                "unit": {
                    "total-count-mode": 1
                },
                "visibilities": {
                    "associations": {
                        "salesChannel": {
                            "total-count-mode": 1
                        }
                    },
                    "total-count-mode": 1
                }
            },
            "ids": id,
            "limit": 25,
            "page": 1,
            "total-count-mode": 1
        }

        const params = new URLSearchParams({})
        let result = await post('/search/product', params, body)

        //console.log(result)
        result = unpackJSONAPI(result, whitelist);

        //console.log(result)
        return result
    },

    async callRelatedUrl(url) {
        return await callUrl(url)
    },

    async upsert(payload) {
        const body = {
            'write-product': {
                entity: 'product',
                action: 'upsert',
                payload: [payload]
            }
        }

        const params = new URLSearchParams
        return await post('/_action/sync', params, body)

    },

    async deleteCurrentCategories(payload) {
        const body = {
            'delete-product-categories': {
                entity: 'product_category',
                action: 'delete',
                payload: payload
            }
        }

        const params = new URLSearchParams();
        return await post('/_action/sync', params, body)

    },

    // Ausnahmsweise hier in ProductRepository und nicht in einem Extra ProductMedia Repository -> eventuell später refaktorisieren
    // Läuft über sync API -> payload muss array sein
    async deleteProductMedia(payload) {

        const body = {
            'delete-productmedia': {
                entity: 'product_media',
                action: 'delete',
                payload: payload
            }
        }
        const params = new URLSearchParams
        return await post('/_action/sync', params, body)
    },

    async uploadMedia(media) {

        const params = new URLSearchParams({extension: 'jpeg'})
        return await postFile("/_action/media/"+media.id+"/upload", params, media)
    },

    async createMediaForProduct(productId, media, setCover) {

        const params = new URLSearchParams()

        const productMediaId = crypto.randomBytes(16).toString("hex")
        const body = {
            media: [{
                id: productMediaId,
                position: media.position,
                media: {
                    id: media.id,
                    mediaFolderId: settings.mediaFolderId
                }
            }]
        }

        if (setCover) {
            body.coverId = productMediaId
        }
        return await patch("/product/"+productId, params, body, false, true)
    },

    loading() {
        return isLoading.value
    }
}

