export const ROLES = {
    ADMIN_AT: 'Bestell Admin AT',
    ADMIN_HQ: 'Bestell Admin HQ',
    ADMIN_IT: 'Bestell Admin IT',
    ADMIN_UK: 'Bestell Admin UK',
    ADMIN_AE: 'Bestell Admin AE',
    ADMIN_ES: 'Bestell Admin ES',
    ADMIN_DK: 'Bestell Admin DK',
    ADMIN_DE: 'Bestell Admin DE GmbH',
    ADMIN_LPM: 'Bestell Admin DE Leitz Park Marketing',
    ADMIN_PT: 'Bestell Admin PT',
    ADMIN_US: 'Bestell Admin US',
    ADMIN_FR: 'Bestell Admin FR',
    ADMIN_CH: 'Bestell Admin CH',
}