import { SWHelpers } from "@/helpers";
import { useSettings } from "@/composables";

const { getAttributeValueOrNull } = SWHelpers();
const { getLanguageIsoFromId, availableLanguages } = useSettings();

export default function() {
    const mapTranslations = ( currentTranslations, fields ) => {
        const translations = {};

        availableLanguages.forEach(lang => {
            const found = findTranslationForLanguage(lang, currentTranslations);

            const translation = {};

            fields.forEach((f) => {
                translation[ f ] = getAttributeValueOrNull( found, f )
            });

            translations[ lang ] = translation;

        });

        return translations;
    }

    const findTranslationForLanguage = (language, translations) => {

        let toReturn = null;
        translations.forEach(translation => {
            const languageId = getLanguageIdFromTranslation( translation );
            const iso = getLanguageIsoFromId( languageId );
            if(iso === language) {
                toReturn = translation;
            }
        })

        return toReturn;
    }

    const getLanguageIdFromTranslation = ( translation ) => {
        return translation.attributes.languageId;
    }

    const getLanguageLabel = ( language ) => {
        return language.substring(0, 2).toUpperCase();
    }

    return {
        mapTranslations,
        getLanguageLabel
    }
}