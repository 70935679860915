import {useAPI} from "@/composables/useAPI";
//import {SWHelpers} from "@/helpers";

const {post, isLoading} = useAPI()
// eslint-disable-next-line no-unused-vars
//const {unpackJSONAPI} = SWHelpers()
//const whitelist = ["categories", "mainCategories", "manufacturer"]

export default {
    async getTaxRates() {
        const body = {
            "page": 1,
            "limit": 500,
            "sort":
                [
                    {
                        "field": "position",
                        "order": "ASC",
                        "naturalSorting": false
                    }
                ],
            "total-count-mode": 1
        }
        const params = new URLSearchParams({})
        return await post('/search/tax/', params, body)
    },

    async getTaxRateDetails(id) {
        const body = {
            "ids": id,
            "page": 1,
            "total-count-mode": 1
        }
        const params = new URLSearchParams({})
        return await post('/search/tax', params, body)
    },

    loading() {
        return isLoading.value
    }
}
