<script setup>
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import {useAPI} from "@/composables/useAPI";
import { useEnvironment } from "@/composables";
import BasicNavigationItem from "@/components/Navigation/BasicNavigationItem.vue";
import DropdownNavigationItem from "@/components/Navigation/DropdownNavigationItem.vue";
import TheAccountMenu from "@/components/Navigation/TheAccountMenu.vue";

const { isTestSystem, isLocalSystem } = useEnvironment();
const { authenticated } = useAPI()

const isAuthenticated = computed(() => {
    return authenticated.value
});


const productChildren = [
    {
        label: 'List',
        route: { name: 'product-list' },
        icon: 'pi-camera'
    },
    {
        label: 'Add New',
        route: { name: 'product-detail', params: { productId: 0 } },
        icon: 'pi-plus-circle'
    }
];

</script>

<template>
    <div
        class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static"
        style="min-height: 80px"
    >
        <img
            src="../../assets/Leica_Classic_Store_RGB_878px_0920.png"
            alt="Leica Logo"
            height="40"
            class="mr-0 lg:mr-6"
        >
        <a
            v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }"
            class="cursor-pointer block lg:hidden text-700 p-ripple"
        >
            <i class="pi pi-bars text-4xl" />
        </a>
        <div
            v-if="isAuthenticated"
            class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
        >
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <BasicNavigationItem
                    label="Home"
                    :route="{ name: 'home' }"
                    icon="pi-home"
                />

                <DropdownNavigationItem
                    label="Products"
                    icon="pi-camera"
                    :children="productChildren"
                />

                <BasicNavigationItem
                    label="Orders"
                    :route="{ name: 'orders' }"
                    icon="pi-money-bill"
                />

                <BasicNavigationItem
                    label="Statistics"
                    :route="{ name: 'order-statistics' }"
                    icon="pi-chart-line"
                    :condition="isTestSystem()"
                />

                <BasicNavigationItem
                    label="Store"
                    :route="{ name: 'store-settings' }"
                    icon="pi-home"
                    :condition="isLocalSystem()"
                />
            </ul>

            <TheAccountMenu />
        </div>
    </div>
</template>

<style scoped>
    a { text-decoration: none; }
</style>