import settings from "@/settings"
import {SWHelpers, TranslationHelper} from "@/helpers"
import {useAPI} from "@/composables/useAPI";
import {useRoles, useSettings} from "@/composables";

const { mapTranslations } = TranslationHelper();
const { findInclude, getAttributeValueOrNull } = SWHelpers();
const { shopConfig } = useRoles();
const { getLanguageIsoFromId, availableLanguages, getTaxId, defaultGpsrTranslations } = useSettings();
const { callUrl } = useAPI();

export default function() {
    const mapShopwareProductToProduct = async (shopwareProduct, properties, productMedia) => {

        const allowDifferentialTaxation = shopConfig.value.allowDifferentialTaxation;

        const shopwareAttributes = shopwareProduct.data[0].attributes
        let product = {}
        //console.log("Product: ", shopwareProduct);

        product.id = shopwareProduct.data[0].id
        product.productNumber = shopwareAttributes.productNumber
        if (shopwareAttributes.taxId === getTaxId('diff') && allowDifferentialTaxation) {
            product.tax = true
        } else {
            product.tax = false
        }
        product.stock = shopwareAttributes.stock
        product.price = shopwareAttributes.price[0].gross
        //product.title = shopwareAttributes.name
        //product.description = shopwareAttributes.description
        //product.metaTitle = shopwareAttributes.metaTitle
        //product.metaDescription = shopwareAttributes.metaDescription
        product.manufacturer = shopwareAttributes.manufacturerId
        product.releaseDate = shopwareAttributes.releaseDate
        product.active = shopwareAttributes.active
        product.stock = shopwareAttributes.stock
        product.deliveryTime = shopwareAttributes.deliveryTimeId
        product.custom_vendorportal_pending_approval = shopwareAttributes?.customFields?.custom_vendorportal_pending_approval
        product.materialNumber = shopwareAttributes.customFields.custom_vendorportal_materialnumber
        product.markAsTopseller = shopwareAttributes.markAsTopseller
        product.currentCategories = shopwareAttributes.categoryIds;

        let selectedCategories = {}
        if (shopwareAttributes.categoryTree && shopwareAttributes.categoryTree.length > 0) {
            shopwareAttributes.categoryTree.forEach((el) => {
                if (shopwareAttributes.categoryIds.includes(el)) {
                    selectedCategories[el] = {checked: true, partialChecked: false}
                } else {
                    selectedCategories[el] = {checked: false, partialChecked: true}
                }
            })
        }
        product.selectedCategories = selectedCategories


        product.serialnumber = shopwareAttributes.customFields.serialnumber

        // Verbesserung: die "originalen" Properties extra sichern, um nur einen Aufruf zu starten?
        properties.data.forEach(propRow => {
            if (propRow.attributes.groupId === settings.propertyGroup.condition) {
                product.condition = propRow.id
                product.origCondition = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.manufactureYear) {
                product.manufactureYear = propRow.id
                product.origmanufactureYear = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.guarantee) {
                product.guarantee = propRow.id
                product.origGuarantee = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.itemCategory) {
                product.itemCategory = propRow.id
                product.origItemCategory = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.color) {
                product.color = propRow.id
                product.origColor = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.format) {
                product.format = propRow.id
                product.origFormat = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.special) {
                product.special = propRow.id
                product.origSpecial = propRow.id
            }
            if (propRow.attributes.groupId === settings.propertyGroup.technique) {
                product.technique = propRow.id
                product.origTechnique = propRow.id
            }
        })





        /* SAP EXTENSION */
        const incSapExtension = shopwareProduct.included.filter((i) => { return i.type === 'lc_sap_prod_ext'; });

        product.consigned = false;
        product.sapExtension = {
            attributes: {}
        };
        if(incSapExtension.length === 1) {
            const sapExtension = incSapExtension[0];

            product.sapExtension = sapExtension;

            if(sapExtension.attributes.materialNumber && sapExtension.attributes.materialNumber !== ''){
                product.materialNumber = sapExtension.attributes.materialNumber;
            }

            if(sapExtension.attributes.serialNumber && sapExtension.attributes.serialNumber !== ''){
                product.serialnumber = sapExtension.attributes.serialNumber;
            }

            if(typeof sapExtension.attributes.consigned !== 'undefined' && sapExtension.attributes.consigned){
                product.consigned = sapExtension.attributes.consigned;
            }

        }


        product.images = []
        let coverFound = false
        productMedia.data.forEach(mediaRow => {

            // Wir gehen hier davon aus, dass die Reihenfolge von media, der Reihenfolge in Shopware (pos) entspricht. Beim Upload nutzen wir für pos den Array Index
            let tmp = findInclude(productMedia.included, mediaRow.attributes.mediaId, ["id"])
            let img = tmp.filter(obj => obj.type === 'media')
            if (img[0]) {
                if (mediaRow.id === shopwareAttributes.coverId || shopwareAttributes.coverId === img[0].id) {
                    img[0].isCover = true
                }
                product.images.push(img[0])
            }
        })

        // Hier setzen, damit wir später darauf beim Speichern Zugriff haben
        product.productMedia = shopwareProduct.data[0].relationships.media.data
        if (!product.productMedia) product.productMedia = []

        return product
    }

    const getExtension = ( productIncludes, extensionName ) => {
        const extensions = productIncludes.filter((i) => { return i.type === extensionName; });
        if(extensions.length === 1) {
            return extensions[0];
        }
        return {
            id: null
        };
    }

    const mapClassicsExtension = async ( productIncludes ) => {
        const classicsExtension = {}

        const currentExtension = getExtension(productIncludes, 'lc_prod_ext');

        let currentTranslations = defaultGpsrTranslations;

        if( currentExtension.id ) {
            classicsExtension.id = currentExtension.id;
            classicsExtension.avalaraTaxNumber = getAttributeValueOrNull(currentExtension, 'avalaraTaxNumber');

            currentTranslations = await callUrl( currentExtension.relationships.translations.links.related );
            currentTranslations = currentTranslations.data;
        }

        classicsExtension.translations = mapTranslations( currentTranslations, ['gpsrManufacturer', 'gpsrNotice'] );

        return classicsExtension;
    }


    return {
        mapShopwareProductToProduct,
        mapClassicsExtension
    }
}
