import { createApp } from 'vue'
import { createPinia } from "pinia";

import PrimeVue from 'primevue/config'
import Accordion from 'primevue/accordion'
import AccordionTab from "primevue/accordiontab"
import AutoComplete from "primevue/autocomplete"
import Avatar from "primevue/avatar";
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from "primevue/checkbox"
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import Divider from "primevue/divider"
import DynamicDialog from 'primevue/dynamicdialog'
import DialogService from 'primevue/dialogservice';
import Editor from "primevue/editor";
import FileUpload from 'primevue/fileupload'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import OrderList from 'primevue/orderlist'
import Password from 'primevue/password';
import ProgressSpinner from "primevue/progressspinner"
import ProgressBar from "primevue/progressbar"
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import Image from 'primevue/image'
import Ripple from 'primevue/ripple'
import Skeleton from 'primevue/skeleton';
import StyleClass from 'primevue/styleclass'
import Tag from "primevue/tag";
import Textarea from "primevue/textarea"
import Toast from "primevue/toast"
import ToastService from 'primevue/toastservice'
import Tree from "primevue/tree"
import TreeSelect from 'primevue/treeselect'
import TreeTable from 'primevue/treetable';

import VueLazyLoad from 'vue3-lazyload';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'                             //primeflex
import '@/css/custom.css'    // custom css include

import {router} from "@/router"
import {useAPI} from "@/composables/useAPI"
import settings from './settings'
import { useEnvironment } from "@/composables";

import App from './App.vue';
const app = createApp(App)
app.provide("settings", settings)

app.use(createPinia());

app.use(router)
app.use(PrimeVue, {
    ripple: true,
})
app.use(VueLazyLoad)
app.use(ToastService)
app.use(DialogService)

// eslint-disable-next-line vue/multi-word-component-names
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('AutoComplete', AutoComplete)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Avatar', Avatar);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Badge', Badge)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Calendar', Calendar);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Card', Card);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Chart', Chart);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Checkbox', Checkbox)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Column', Column)
app.component('DataTable', DataTable)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Divider', Divider)
app.component('DynamicDialog', DynamicDialog)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dropdown', Dropdown)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Editor', Editor)
app.component('FileUpload', FileUpload)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('OrderList', OrderList)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Password', Password)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
app.component('SelectButton', SelectButton)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Skeleton', Skeleton)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Image', Image)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Tag', Tag)
app.component('TextArea', Textarea)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Tree', Tree)
app.component('TreeSelect', TreeSelect)
app.component('TreeTable', TreeTable)

app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

const { isProductionSystem } = useEnvironment();
const { loadUser } = useAPI()
loadUser()

router.isReady().then(() => {
    app.mount('#app');

    if(! isProductionSystem()) {
        document.body.classList.add('dev-system');
    }
});
